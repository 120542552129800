/*
*	MG2 Media JS Framework
*	@author  François Vandal
*	@version: 0.9.4
*	Last update: 6 september 2016
*/

(function($, global, undefined) {
	var html = $('html');
	var lang = html.attr('lang');
	var defaultLang = html.attr('data-default-lang');
	
	var modules = {};
	var components = {};
	var pages = {};
	
	/* Modules */
	var createModule = function (module) {
		return $.extend({
			init: $.noop,
			pageLoad: $.noop, 
			pageChange: $.noop,  
			pageEnter: $.noop,
			pageLeave: $.noop,
			pageStartChange: $.noop
		}, module);
	};
	
	var exportModule = function (key, module) {
		modules[key] = createModule(module);
		
		return modules[key];
	};
	
	
	/* Pages */
	var createPage = function (page) {
		return $.extend({
			init: $.noop,
			pageLoad: $.noop,
			pageEnter: $.noop,
			pageLeave: $.noop
		}, page);
	};
	
	var exportPage = function (key, page) {
		pages[key] = createPage(page);
		
		return pages[key];
	};
	
	var checkForPage = function (pageIdNum) {
		var currPage = $('.main-content .page[data-page-id-num='+pageIdNum+']').attr('data-page-id');
		
		if (pages[pageIdNum] !== undefined) {
			pages[pageIdNum].init('#page-'+currPage);
			pages[pageIdNum].pageLoad('#page-'+currPage);
		}
	};
	
	
	/* Components */
	var createComponent = function (component) {
		return $.extend({init: $.noop}, component);
	};
	
	var exportComponent = function (key, component) {
		components[key] = createPage(component);
		
		return pages[key];
	};
	
	
	var start = function () {
		var lang = $('html').attr('lang');
		var defaultLang = $('html').attr('data-default-lang');
		
		var currPathname = location.pathname;
		var currentIdNum = $('.main-content .page').data('pageIdNum');
		
		if (currPathname !== '/' && currPathname.substring(0, 1) === '/') {
			currPathname = currPathname.substring(1);
		}
		
		if (lang !== defaultLang) {
			currPathname = currPathname.substring(lang.length);
		}
		
		currPathname = currPathname !== '/' ? currPathname : 'home';
		
		if (currPathname.substring(currPathname.length - 1) === '/') {
			currPathname = currPathname + 'home';
		}
		
		if (currPathname.substring(0, 1) === '/') {
			currPathname = currPathname.substring(1);
		}
		
		currPathname = currPathname.split('?')[0];
		if (currPathname.substring(currPathname.length - 1) === '/') {
			currPathname = currPathname.substring(0, currPathname.length - 1);
		}
		
		if (lang !== defaultLang) {
			if(currPathname.substring(0, lang.length) === lang) {
				currPathname = currPathname.substring(lang.length);
			}
		}
		
		if (currPathname.substring(0, 1) === '/') {
			currPathname = currPathname.substring(1);
		}
		
		currPathname = currPathname.split('/')[0];
		
		$.each(Fw.modules.models(), function () {
			this.init('body');
			this.pageLoad('body');
		});
		
		$('html').attr('data-curr-page', 'page-' + currPathname);
		checkForPage(currentIdNum);
		
		return Fw;
	};
	
	global.Fw = $.extend(global.Fw, {
		data: '',
		
		modules: {
			models: function () {
				return modules;
			},
			
			export: exportModule
		},
		
		component: {
			models: function () {
				return components;
			},
			
			export: exportComponent
		},
		
		page: {
			models: function () {
				return pages;
			},
			
			export: exportPage,
			
			checkForPage: checkForPage
		},
		
		start: start
	});
	
})(jQuery, window);