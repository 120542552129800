(function($, undefined) {
	
	'use strict';
	
	var addExternalLinkTarget = function () {
		$.expr[':'].external = function(obj){
			var hostname = obj.hostname === '' ? location.hostname : obj.hostname;
			
			return !obj.href.match(/^mailto\:/) &&
			(hostname != location.hostname) &&
			!obj.href.match(/^javascript\:/) &&
			!obj.href.match(/^tel\:/) &&
			!obj.href.match(/^$/) ||
			obj.href.match(/\.pdf$/);
		};
		
		$('a:external').attr('target', '_blank');
		
	};
	
	
	var init = function () {
		addExternalLinkTarget();
	};
	
	
	$(window).on('load', function () {
		init();
	});
	
})(jQuery);
