(function($, undefined) {
	
	'use strict';
	var ctn;
	var input;
	
	
	var checkIfHasChar = function () {
		var t = $(this);
		var label = t.closest('label');
		
		if (t.val().length !== 0) {
			label.addClass('has-char');
		} else {
			label.removeClass('has-char');
		}
	};
	
	var addFocus = function () {
		var t = $(this);
		var label = t.closest('label');
		
		label.addClass('has-focus');
	};
	
	var removeFocus = function () {
		var t = $(this);
		var label = t.closest('label');
		
		label.removeClass('has-focus');
	};
	
	var init = function () {
		var ctn = $('.contact-plugin');
		var input = $('input, textarea', ctn);
		
		input.change(checkIfHasChar);
		input.focus(addFocus);
		input.blur(removeFocus);
		
		input.each(function() {
			checkIfHasChar.apply($(this));
		});
	};
	
	
	$(init);
	
})(jQuery);
