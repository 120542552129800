(function($, undefined) {
	
	'use strict';
	var header;
	var openBtn;
	var closeBtn;
	
	var toggleMenu = function () {
		if (header.hasClass('open')) {
			closeMenu();
		} else {
			openMenu();
		}
	};
	
	var openMenu = function() {
		$('body').addClass('menu-open no-scroll');
	};
	
	var closeMenu = function () {
		$('body').removeClass('menu-open no-scroll');
	};
	
	
	var init = function () {
		header = $('.site-header');
		openBtn = $('.site-header-menu-open-btn');
		closeBtn = $('.site-header-menu-close');
		
		openBtn.click(openMenu);
		closeBtn.click(closeMenu);
	};
	
	
	$(window).on('load', function () {
		init();
	});
	
})(jQuery);
