(function($, undefined) {
	
	'use strict';
	var topBtn;
	
	var scrollTop = function () {
		$('html, body').animate({scrollTop: 0}, 500); 
	};
	
	var init = function () {
		topBtn = $('.site-footer-to-top-btn');
		
		topBtn.click(scrollTop);
	};
	
	
	$(window).on('load', function () {
		init();
	});
	
})(jQuery);
