(function($, undefined) {

	'use strict';
	var doc = $(document);
	var transitionEnd = 'transitionend webkitTransitionEnd oTransitionEnd otransitionend';
	var body;
	var items;
	var checkLabel;
	var checkboxItems;
	var checkboxItemsInner;
	var file;
	var fileInput;
	var formSwitch;
	var formCheck;

	var validateForm = function (ctn) {
		ctn.each(function () {
			var t = $(this);
			var toValidateItems = $('.form-item', t);
			var toValidateUpload = $('.form-input-upload.required', t);
			var toValidateCheck = $('.form-check-input.required', t);
			var password = $('.js-password', t);
			var passwordConfirm = $('.js-password-confirm', t);
			var btn = $('.js-form-btn', t);
			var isOk = true;
			var passwordOk = true;
			var termsOk = true;
			var checkOk = true;
			var fieldsErrorMessage = $('.js-form-error-message.fields', t);
			var fieldsErrorCheckbox = $('.js-form-error-message.checkbox', t);
			var fieldsErrorTerms = $('.js-form-error-message.terms', t);
			var fieldsErrorPassword = $('.js-form-error-message.password', t);

			toValidateItems.each(function () {
				var tChild = $(this);
				validateField(tChild);
				if (tChild.hasClass('error')) {
					isOk = false;
				}
			});

			toValidateUpload.each(function () {
				var tUpload = $(this);

				if (tUpload.val() === '') {
					tUpload.addClass('error');
				}

				if (tUpload.hasClass('error')) {
					isOk = false;
				}
			});

			toValidateCheck.each(function () {
				var tCheck = $(this);
				var tCtn = tCheck.closest('.form-checkbox-ctn');

				if (!tCheck.is(':checked')) {
					tCtn.addClass('error');
					
					if (tCtn.attr('name') !== 'terms') {
						checkOk = false;
					}
				} else {
					tCtn.removeClass('error');
				}

				if (tCheck.attr('name') === 'terms' && tCtn.hasClass('error')) {
					termsOk = false;
				}

				if (tCtn.hasClass('error')) {
					termsOk = false;
				}
			});

			if (password.length !== 0 && passwordConfirm.length !== 0 && (password.val() !== passwordConfirm.val() || password.val().length === 0)) {
				if (password.val().length === 0) {
					password.removeClass('success').addClass('error');
					passwordOk = false;
				} else {
					password.addClass('success').removeClass('error');
					passwordOk = true;
				}

				if (password.val() !== passwordConfirm.val()) {
					passwordConfirm.removeClass('success').addClass('error');
					passwordOk = false;
				} else {
					passwordConfirm.removeClass('error');
					passwordOk = true;

					if (password.val().length !== 0) {
						passwordConfirm.addClass('success');
					}
				}
			}

			t.addClass('is-checked');

			if (!termsOk) {
				fieldsErrorTerms.slideDown(300);
			} else {
				fieldsErrorTerms.slideUp(300);
			}
			
			if (!checkOk) {
				fieldsErrorCheckbox.slideDown(300);
			} else {
				fieldsErrorCheckbox.slideUp(300);
			}

			if (password.length !== 0 && passwordConfirm.length !== 0) {
				if (password.length !== 0 && passwordOk) {
					ctn.addClass('has-password');
					password.add(passwordConfirm).removeClass('error');
					fieldsErrorPassword.slideUp(300);
				} else {
					ctn.removeClass('has-password');
					password.add(passwordConfirm).addClass('error');
					fieldsErrorPassword.slideDown(300);
				}
			}

			if (!isOk) {
				fieldsErrorMessage.slideDown(300);
			} else {
				fieldsErrorMessage.slideUp(300);
			}

			if (!isOk || !passwordOk || !termsOk || !checkOk) {
				btn.addClass('disabled');
				ctn.addClass('has-error');

				if (!passwordOk && password.hasClass('required')) {
					ctn.addClass('error-password');
				} else {
					ctn.removeClass('error-password');
				}
				return false;
			} else {
				btn.removeClass('disabled');
				ctn.removeClass('has-error').removeClass('error-password');

				fieldsErrorTerms.slideUp(300);
				return true;
			}
		});
	};

	var validateField = function (elt) {
		var tag = elt[0].tagName;
		var type = elt.attr('type');
		var val = elt.val();
		var attrClass = elt.attr('data-validator-apply-class-on');
		var classTarget = attrClass !== undefined ? elt.closest(attrClass) : elt;
		//Choises for validate are : "email", "url", "tel" or "default". By default, an input type email takes the email validation,
		//the input type tel takes the tel validation and if the validate value is "default", then it do a normal check if the input has char
		var validate = elt.attr('data-validate');
		var reEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		var reUrl = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.‌​-]+(:[0-9]+)?|(?:www‌​.|[-;:&=\+\$,\w]+@)[‌​A-Za-z0-9.-]+)((?:\/‌​[\+~%\/.\w-_]*)?\??(‌​?:[-\+=&;%@.\w_]*)#?‌​(?:[\w]*))?)/;
		var reTel = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
		var emailValid = true;
		var whiteSpaceValid = true;
		var urlValid = true;
		var telValid = true;
		var inputValid = true;

		if(!val.replace(/\s/g, '').length) {
			whiteSpaceValid = false;
		}

		if (tag === 'INPUT' && (type === 'email' || validate === 'email') && validate !== 'default') {
			emailValid = reEmail.test(val);
		} else if (tag === 'INPUT' && validate === 'url' && validate !== 'default') {
			urlValid = reUrl.test(val);
		} else if (tag === 'INPUT' && (type === 'tel' || validate === 'tel') && validate !== 'default') {
			telValid = reTel.test(val);
		} else if (elt.hasClass('form-input-upload-input')) {
			if (classTarget.hasClass('required') && elt.val() !== '') {
				classTarget.removeClass('error').addClass('success');
			} else {
				classTarget.addClass('error').removeClass('success');
			}
		} else {
			classTarget.addClass('success').removeClass('error');
			if (classTarget.hasClass('required') && val.length === 0) {
				classTarget.removeClass('success').addClass('error');
                inputValid = false;
			} else if (!classTarget.hasClass('required') && val.length === 0) {
				classTarget.removeClass('success').removeClass('error');
			}
		}

		if (emailValid && urlValid && telValid && inputValid && whiteSpaceValid) {
			classTarget.removeClass('error').addClass('success');
		} else if (val.length === 0 && !classTarget.hasClass('required')) {
			classTarget.removeClass('error').removeClass('success');
		} else {
			classTarget.addClass('error').removeClass('success');
		}
	};

	var formItemsBlur = function (t) {
		var elt = t.target === undefined ? t : $(this);
		var form = elt.closest('.js-form');

		validateField(elt);

		if (form.hasClass('is-checked')) {
			validateForm(form);
		}
	};

	var formCheckChange = function () {
		var t = $(this);
		var ctn = t.closest('.form-checkbox-ctn');
		var form = ctn.closest('.js-form');
		
		if (form.hasClass('is-checked')) {
			validateForm(form);
			
			if (t.is(':checked')) {
				ctn.removeClass('error');
			}
		}
	};

	var checkLabelClick = function () {
		var t = $(this);
		var ctn = t.closest('.register-checkbox');

		if (input.hasClass('is-checked')) {
			input.removeClass('is-checked');
		} else {
			input.addClass('is-checked');
		}
	};

	var toggleCheckbox = function () {
		var t = $(this);
		var ctn = t.closest('.form-input-checkbox');
		var input = $('.form-input-checkbox-input', t);

		if (ctn.hasClass('checked')) {
			ctn.removeClass('checked');
			input.removeAttr('checked');
		} else {
			ctn.addClass('checked');
			input.attr('checked', 'checked');
		}
	};


	var fileInputChange = function () {
		var t = $(this);
		var form = t.closest('.js-form');

		if (t.val() !== '') {
			t.removeClass('error').addClass('success');

		} else {
			t.addClass('error').removeClass('success');
		}

		if (form.hasClass('is-checked')) {
			validateForm(form);
		}
	};


	var formSwitchClick =function (){
		var t = $(this);
		var elem = $(t).find('.checkbox');

		t.find('.form-checkbox-slider').toggleClass('active');
		if (elem.prop( "checked" )){
			elem.removeAttr( "checked" );
		}else{
			elem.attr( "checked", "checked" );
		}
	};


	var init = function () {
		body = $('body');
		items = $('.form-item:not([type=file])');

		checkLabel = $('.register-checkbox-label');
		checkboxItems = $('.form-item-checkbox');
		checkboxItemsInner = $('.form-item-checkbox-inner', checkboxItems);
		file = $('.form-input-upload');
		formSwitch = $('.form-switch');
		formCheck = $('.form-check-item');

		checkLabel.click(checkLabelClick);
		checkboxItemsInner.click(toggleCheckbox);
		file.change(fileInputChange);
		formSwitch.click(formSwitchClick);
		formCheck.change(formCheckChange);

		items.each(function () {
			if (!$(this).hasClass('no-blur')) {
				$(this).blur(formItemsBlur);
			}
		});

	};


	var form = Fw.component.export('form', {
		init: init,
		validateForm: validateForm
	});

})(jQuery);
