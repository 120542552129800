(function($, undefined) {
	
	'use strict';
	var slider;
	
	var changePetalY = function () {
		var elt = $('.home-gallery-img-ctn');
		
		for(var i = 0; i < elt.length; i++) {
			var top = elt.eq(i).css('top');
			var img = $('img', elt.eq(i));
			var factor = img.data('factor');
			//var translateVal = ((win.scrollTop() - parseInt(top)) / (winHeight / 2)) * 100;
			//img.css('transform', 'translateY('+translateVal+'%)')
			
			var translateVal = ($(window).scrollTop() - ($(window).height() * 0.75) - parseFloat(top)) * factor;
			img.css('top', translateVal+'px');
		}
	};
	
	var init = function () {
		
		changePetalY();
		
		$(window).scroll(function(){
			changePetalY();
		});
	};
	
	
	$(init);
	
})(jQuery);
