(function($, undefined) {
	
	'use strict';
	var header;
	
	var itemBtn;
	var allPopups;
	
	var closeBtn;
	
	var openLightbox = function () {
		var t = $(this);
		var id = t.attr('data-id');
		
		var lb = $('.lightbox-popup[data-id="'+id+'"]:eq(0)');
		var lbSlideShow = $('.lightbox-slideshow', lb);
		var lbDataImg = $('.lightbox-img', lb);
		var lbContent = $('.lightbox-item-content', lb);
		var lbCurr = 1;
		var lbTotal = lbDataImg.length;
		var loadedCount = 0;
		
		$('body').addClass('show-overlay');
		lb.addClass('open').attr('data-count', lbCurr).attr('data-total', lbTotal);
		
		if (!lb.hasClass('already-loaded')) {
			lbDataImg.each(function (){
				var tImg = $(this);
				var tSrc = tImg.attr('data-src');
				var tClass = tImg.attr('class');
				var tAlt = tImg.attr('data-alt');
				
				tImg.append('<div class="lightbox-img-ctn"><img src="'+tSrc+'" alt="'+tAlt+'" /></div>');
				$('.lightbox-img-ctn',tImg).append(lbContent.clone());
			});
			
			lbContent.remove();
			
			$('.lightbox-img-ctn img', lb).on('load', function() {
				loadedCount++;
				
				if (loadedCount === lbTotal) {
					$('.lightbox-top-content', lb).show();
					$('.lightbox-loading', lb).hide();
					
					lbSlideShow.slick({
						arrows: false,
						swipe: false,
						speed: 300
					});
					
					$('.lightbox-nav-btn.prev').click(function () {
						lbSlideShow.slick('slickPrev');
						lbUpdateCount(-1, lb);
						
					});
					
					$('.lightbox-nav-btn.next').click(function () {
						lbSlideShow.slick('slickNext');
						lbUpdateCount(1, lb);
					});
					
					lbUpdateCount(0, lb);
					lb.addClass('already-loaded');
					$('.lightbox-progress-total', lb).html(lbTotal);
					
					recalHeight();
					$(document).on('click', outsideClick);
				}
			});
		} else {
			setTimeout(function () {
				$(document).on('click', outsideClick);
			}, 500);
		}
	};
	
	var recalHeight = function () {
		$('.lightbox-popup.already-loaded').each(function () {
			var t = $(this);
			
			$('.lightbox-slideshow', t).height($('.lightbox-top', t).height());
			
			$('.lightbox-img-ctn', t).each(function () {
				var tCtn = $(this);
				var ctnW = tCtn.width();
				
				$('> img', tCtn).each(function () {
					var tImg = $(this);
					var tImgW = tImg.width();
					
					if(tImgW > ctnW) {
						var totalBleed = ctnW - tImgW;
						
						tImg.css('margin-left', (totalBleed / 2)+'px');
					}
				});
			});
		});
	};
	
	var lbUpdateCount = function (valtoAdd, lb) {
		var curr = parseInt(lb.attr('data-count'));
		var total = parseInt(lb.attr('data-total'));
		$('.lightbox-nav-btn', lb).attr('disabled', 'disabled');
		
		var index = curr + valtoAdd;
		
		if (index < 1) {
			index = total;
		} else if (index > total) {
			index = 1;
		}
		
		lb.attr('data-count', index);
		$('.lightbox-progress-current', lb).html(index);
		$('.lightbox-progress-bar', lb).width((100 * (index / total) ) + '%');
		
		setTimeout(function () {
			$('.lightbox-nav-btn', lb).removeAttr('disabled');
		}, 300);
	};
	
	var closeLightbox = function () {
		$('body').removeClass('show-overlay');
		allPopups.removeClass('open');
		$(document).off('click', outsideClick);
	};
	
	var outsideClick = function (event) {
		if (!$(event.target).closest('.lightbox-top-content').length) {
			console.log('outsideClose');
			closeLightbox();
		} else {
			console.log('NOToutsideClose');
		}
	};
	
	var init = function () {
		itemBtn = $('.lightbox-btn');
		allPopups = $('.lightbox-popup');
		
		closeBtn = $('.lightbox-close-btn');
		
		itemBtn.click(openLightbox);
		closeBtn.click(closeLightbox);
		
		$(window).resize(function() {
			recalHeight();
		});
	};
	
	
	$(window).on('load', function () {
		init();
	});
	
})(jQuery);
